<template>
  <v-container class="mt-5">
    <v-row v-if="!productsCount">

      <!--left side filters-->
      <v-col cols="12" lg="3">

        <!--categories filter-->
        <v-card>
          <v-card-title class="bg-grey">All Categories</v-card-title>
          <v-list>
            <v-list-group
              v-for="(category, index) in categories"
              :key="'cat'+index"
              :value="false"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ category.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item-group
                v-if="category.childrens"
                v-model="selectedCategories[category.id]"
                value="name"
                @change="selectCategory(category.id, $event)"
              >
                <v-list-item
                  v-for="(item, index) in category.childrens"
                  :key="'sub'+ index">
                  <template #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                        dense
                      />
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list-group>
          </v-list>
        </v-card>

        <!--price range filter-->
        <v-card class="mt-5">
          <v-card-title class="text-center bg-grey">Price Range</v-card-title>
          <v-card-text class="text-center">
            <v-range-slider
              v-model="priceRange"
              :max="vendorPriceRange.max"
              :min="vendorPriceRange.min"
              class="align-center"
              hide-details
              @change="updateData"
            />
            <div class="text-center font-weight-bold">
              {{ priceRange[0] | currency }} - {{ priceRange[1] | currency }}
            </div>
          </v-card-text>
        </v-card>

        <!--featured products-->
        <!--    will implement Later    -->
<!--        <v-card class="mt-5">-->
<!--          <v-tabs v-model="tab" background-color="primaryDark" dark grow>-->
<!--            <v-tabs-slider></v-tabs-slider>-->
<!--            <v-tab href="#tab-1"> Popular</v-tab>-->
<!--            <v-tab href="#tab-2"> Latest</v-tab>-->
<!--            <v-tab href="#tab-3"> Deals</v-tab>-->
<!--          </v-tabs>-->

<!--          <v-tabs-items v-model="tab">-->
<!--            <v-tab-item-->
<!--              v-for="i in 3"-->
<!--              :key="i"-->
<!--              :value="'tab-' + i"-->
<!--            >-->
<!--              <popular-product />-->
<!--            </v-tab-item>-->
<!--          </v-tabs-items>-->
<!--        </v-card>-->
      </v-col>


      <v-col cols="12" lg="9">

        <!--top filters-->
        <v-card class="mb-5" outlined>
          <v-card-text>
            <v-row class="d-flex justify-center align-center align-content-center">
              <v-col cols="12" lg="10">
                <v-row>
                  <v-col cols="12" lg="3" md="6">
                    <v-autocomplete
                      v-model="basicFilter"
                      :items="basicSortOption"
                      clearable
                      dense
                      hide-details
                      item-text="name"
                      item-value="value"
                      outlined
                      placeholder="Sort BY"
                      @change="updateData"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3" md="6">
                    <v-autocomplete
                      v-model="filterByBrand"
                      :items="allBrands"
                      clearable
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                      outlined
                      placeholder="All Brands"
                      @change="updateData"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3" md="6">
                    <v-text-field
                      v-model="search"
                      clearable
                      dense
                      hide-details
                      outlined
                      placeholder="Search"
                      prepend-inner-icon="mdi-magnify"
                      @input="updateData"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!--product view type-->
              <v-col cols="12" lg="2">
                <v-spacer></v-spacer>
                <div class="d-flex align-content-end justify-end">
                  <v-btn
                    :class="{'secondary--text': viewMode == 'grid'}"
                    icon
                    @click="viewMode = 'grid'"
                  >
                    <v-icon size="30">mdi-view-grid-outline</v-icon>
                  </v-btn>
                  <v-btn
                    :class="{'secondary--text': viewMode == 'list'}"
                    icon
                    @click="viewMode = 'list'"
                  >
                    <v-icon size="35">mdi-view-list</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>

        <!--products-->
        <component
          :is="currentView"
          :loading="dataLoading"
          :products="products"
        />

        <!--pagination-->
        <v-card>
          <v-card-actions class="text-center align-content-center justify-center mt-5">
            <v-pagination
              v-model="pagination"
              :disabled="!products.length"
              :length="paginationLength"
              circle
              color="secondary"
              @input="updateData"
              :total-visible="8"
            />
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>

    <!--no product content-->
    <v-row v-else align-content="center" justify="center">
      <v-alert border="right" colored-border elevation="2" type="warning">
        <h3 class="title">This vendor has no products available to sell</h3>
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import PopularProduct from '@/components/Product/PopularProduct';
import ProductSlider from '@/components/Product/ProductSlider';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'allProducts',
  components: { ProductSlider, PopularProduct },
  data: () => ({
    vendorSlug: '',
    viewMode: 'grid',
    slideItems: [],
    tab: null,
    selectedCategories: {},
    selectedSort: {},
    priceRange: [0, 0],
    dataLoading: false,
  }),
  computed: {
    ...mapGetters({
      categories: 'vendor/vendorCategories',
      vendorPriceRange: 'vendor/vendorPriceRange',
      basicSortOption: 'vendor/basicSortOption',
      allBrands: 'vendor/vendorBrands',
      products: 'vendor/vendorProducts',
      paginationLength: 'vendor/paginationLength',
      productsCount: 'vendor/productsCount'
    }),

    pagination: {

      get() {
        return this.$store.state.vendor.filters.page;
      },

      set(payload) {
        this.applyFilter({ type: 'page', data: payload });
      }

    },

    currentView() {

      if (this.viewMode === 'grid') {
        return () => import('@/components/Product/ProductGrid');
      }

      return () => import('@/components/Product/ProductList');

    },

    basicFilter: {

      get() {
        return '';
      },

      set(payload) {

        let sortType = 'date';
        let sortValue = 1;

        switch (payload) {

          case 'sortBestSelling':
            sortType = 'popular'
            sortValue = 1;
          break;

          case 'sortLowPrice' :
          case 'sortHighPrice' :
            sortType = 'price';
            sortValue = payload === 'sortLowPrice' ? 0 : 1;
          break;

          case 'sortOld' :
          case 'sortNew' :
            sortType = 'date';
            sortValue = payload === 'sortNew' ? 1 : 0;
          break;
        }


        let data = {
          sort_by: sortType,
          sort_high: sortValue
        };

        this.applyFilter({ type: 'basic', data: data });
      }
    },

    filterByBrand: {

      get() {
        return this.$store.state.vendor.filters.brand;
      },

      set(payload) {
        this.applyFilter({ type: 'brand', data: payload });
      }
    },

    search: {

      get() {
        return this.$store.state.vendor.filters.searchString;
      },

      set(string) {
        this.applyFilter({ type: 'searchString', data: string });
      }

    }
  },

  watch: {
    priceRange: {

      handler(range) {

        // if (range[1] === 0) return;

        const payload = {

          min: range[0],
          max: range[1]
        };

        this.applyFilter({ type: 'priceRange', data: payload });

      },
      immediate: true,
      deep: true
    },
    vendorPriceRange: {

      immediate: true,
      deep: true,

      handler(nv){

        if(nv.isDefault) return;

        this.priceRange = [nv.min, nv.max]
      },
    },
  },

  methods: {
    ...mapActions({
      applyFilter: 'vendor/applyFilter'
    }),

    updateData: _.debounce(function () {

      this.getProduct();

    }, 350),

    async getProduct() {
      this.dataLoading = true;
      let slug = this.$route.params.slug;
      await this.$store.dispatch('vendor/getVendorProducts', slug);
      this.dataLoading = false;
    },

    selectCategory(id, event) {

      if (event === undefined) {
        this.applyFilter({ type: 'category', data: null });
        this.updateData();
        return null;
      }

      const categories = [...this.categories];

      const category = categories.filter((categoryItem) => categoryItem.id === id)[0];
      const categoryId = category?.childrens[event].id;

      this.applyFilter({ type: 'category', data: categoryId });
      this.updateData();
    },
  },
  async mounted() {
    await this.getProduct();
    this.priceRange = [this.vendorPriceRange.min, this.vendorPriceRange.max]
  }
};
</script>
